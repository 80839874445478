.cardPosition{
   position: relative;
}
.fav{
   font-size: 30px;
   font-weight:lighter;
   color: #fff;
   position: absolute;
   top: 4%;
   right: 6%;
}
.fwLighter{
   font-weight:lighter;
}