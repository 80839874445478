.bgImage {
   background-image: url('../../../assets/hotels/romancebg.png') !important;
   background-position: center;
   height: 140vh;
   background-repeat: no-repeat;
   background-size: cover; 
 }
 @media (max-width:600px) {

   .romanceLayer{
      background: #ffffff93 !important;
   }
 }
 .fixedHight{
   height: 200px;
 }
.bgHotelRoom{
   background-image: url("../../../assets/hotels/Group\ 238.png");
   background-position:100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
}
.category{
   position: relative;
}
.categoryName{
   position: absolute;
   top: 75%;
   left:7%;
}
.item:hover{
   transform: scale(1.03);
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   transition: transform 0.3s ease, box-shadow 0.3s ease;}

   .item img {
      object-fit: cover;
    }
    
    .item h4 {
      font-size: 1.8rem;
    }
    
    .item h6 {
      font-size: 1.2rem;
    }