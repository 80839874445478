/* Custom Popup Styles */
.popup-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 1000;
 }
 
 .popup-content {
   background: #fff; /* White background */
   padding: 20px 30px;
   border-radius: 8px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   text-align: center;
   max-width: 400px;
   width: 90%;
 }
 
 .popup-message {
   font-size: 16px;
   color: #4caf50; /* Success green */
   margin-bottom: 20px;
 }
 
 .popup-close-btn {
   background: #4caf50; /* Success green */
   color: #fff;
   border: none;
   padding: 10px 20px;
   border-radius: 5px;
   cursor: pointer;
   font-size: 14px;
   transition: background 0.3s;
 }
 
 .popup-close-btn:hover {
   background: #43a047; /* Darker green on hover */
 }
 