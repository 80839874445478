/* Background.module.css */
.backgroundTransparent {
   background-image: url('../../assets/facilites/Rectangle 4 (2).png');
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   width: 100%;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
.backgroundPool{
   background-image: url('../../assets/swimming pools/Swimming\ Pool/sunset pool.JPG');
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   width: 100%;
   height: 100vh;

}
.overlay-container {
   position: relative;
   width: 100%;
   height: 100vh; 
}
.image-container{
   height: 100vh ;
}
.image-container img {
   object-fit: cover;
   width: 100% !important;
   height: 100%;
}
.img-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%; 
}

.img-container img {
    width: 100%;
    height: 100%; 
    object-fit: cover; 
    transition: transform 0.3s ease;
}

.img-container:hover img {
    transform: scale(1.05);
}

.big-image {
    height: 100%; 
}

.small-image {
    flex: 1 1 50%; 
    margin-bottom: 8px;
}

.cardPosition{
    position: relative;
 }
 .fav{
    font-size: 30px;
    font-weight:lighter;
    color: #fff;
    position: absolute;
    top: 4%;
    right: 6%;
 }
 .fwLighter{
    font-weight:lighter;
 }
 .img-container {
   position: relative;
 }
 
 .overlay {
   position: absolute;
   top: 70% !important;
   left: 50%;
   transform: translate(-50%, -50%);
   background: rgba(0, 0, 0, 0.5);
   padding: 10px;
   border-radius: 10px;
   width: 80%;
 }
 
 .overlay h3,
 .overlay p {
   margin: 0;
 }
 /* Styling for the div container */
.thumbnail-container {
   position: relative;
   overflow: hidden;
   cursor: pointer;
   transition: transform 0.3s ease, box-shadow 0.3s ease;
 }
 
 /* Hover effect for the div */
 .thumbnail-container:hover {
   transform: scale(1.05); /* Slightly enlarge the div */
   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow */
 }
 