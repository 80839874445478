.bgHotelRoom{
   background-image: url("../../assets/home/Hotel-rooms-bg.png");
   background-position:100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
}
.layer{
   background-color: #e6eef5b1;
   width: 100%;
   height: 100%;
}
.bgSecondary{
   background-color: var(--secondary-color);
}
.headerStyle{
  font-size: 50px;
}
.positionRelative {
  position: relative;
}

.svgOverlay1 {
  position: absolute;
  bottom: 80% ;
  left: 0;
  width: 100%;
  z-index: 1;
}

.svgOverlay2 {
  position: absolute;
  bottom:-1% ;
  left: 0;
  width: 100%;
  z-index: 1; 
}
@media (max-width: 1200px) {
  .svgOverlay1 {
    bottom: 75%; 
  }
  .svgOverlay2 {
    bottom:2% !important;
  }
}
@media (max-width: 1145px) {
  .svgOverlay2 {
    bottom:5% !important;
  }
}
@media (max-width: 1052px) {
  .svgOverlay2 {
    bottom:9% !important;
  }
}
@media (max-width: 980px) {
  .svgOverlay1 {
    bottom: 67% !important; 
  }
  .svgOverlay2 {
    bottom:11% !important;
  }
}
@media (max-width: 910px) {

  .svgOverlay2 {
    bottom:15% !important;
  }
}
@media (max-width: 768px) {
  .svgOverlay1 {
    bottom: 72% !important; 
  }
  .svgOverlay2 {
    bottom:-1% !important;
  }
}
@media (max-width: 755px) {
  .svgOverlay1 {
    bottom: 72% !important; 
  }
  .svgOverlay2 {
    bottom:5% !important;
  }
}
@media (max-width: 633px) {
  .svgOverlay1 {
    bottom: 72% !important; 
  }
  .svgOverlay2 {
    bottom:10% !important;
  }
}
@media (max-width: 577px) {
  .svgOverlay1 {
    bottom: 95% !important; 
  }
  .svgOverlay2 {
    bottom:-20% !important;
  }
}
@media (max-width: 455px) {
  .svgOverlay1 {
    bottom: 85% !important; 
  }
  .svgOverlay2 {
    bottom:-10% !important;
  }
}
 .container1 {
  position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
 }
 .bordered{
   height: 90%;
   width: 80%;
   /* background-color: #40677b; */
   border-radius: 45% 55% 39% 56% / 57% 54% 46% 43%;
   overflow: hidden;
   box-shadow: 0px 0px 4px -1px;
   animation: animate-border-radius 20s linear infinite, fade-in-border 10s forwards;
   
   img {
     width: 130%;
     height: 100%;
   }
 }
 
 @keyframes animate-border-radius {
   0% {
     border-radius: 30% 70% 38% 62% / 47% 37% 63% 53%;
   }
   
   20% {
     border-radius: 42% 58% 58% 42% / 34% 41% 59% 66%;
   }
   
   40% {
     border-radius: 56% 44% 62% 38% / 44% 22% 78% 56%;
   }
   
   60% {
     border-radius: 60% 40% 38% 62% / 52% 35% 65% 48%;
   }
   
   80% {
     border-radius: 60% 40% 49% 51% / 52% 61% 39% 48%;
   }
   
   100% {
     border-radius: 30% 70% 38% 62% / 47% 37% 63% 53%;
   }
 }
 @keyframes fade-in-border {
   0% {
     box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0);
   }
   100% {
     box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 1);
   }
 }
@media screen and(max : 576px) {
   .width{
width: 20% !important;
   }
}


.bordered {
  height: 90%;
  width: 80%;
  border-radius: 45% 55% 39% 56% / 57% 54% 46% 43%;
  overflow: hidden;
  box-shadow: 0px 0px 4px -1px;
  animation: animate-border-radius 20s linear infinite, fade-in-border 10s forwards;

  img {
    width: 130%;
    height: 100%;
  }
}

/* Mobile styles */
@media (max-width: 767px) {
  .bordered {
    height: 80%;  /* Adjust the height for smaller screens */
    width: 70%;   /* Adjust the width for smaller screens */
    border-radius: 50% 50% 40% 40% / 60% 60% 40% 40%; /* Adjust the border radius for a more compact shape */
    
    img {
      width: 120%; /* Reduce the image width for better fit */
      height: 100%;
    }
  }
}
