.custom-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: #fff;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   cursor: pointer;
   z-index: 2;
 }
 
 .custom-arrow i {
   font-size: 20px;
   color: #333;
 }
 
 .next-arrow {
   right: -20px;
 }
 
 .prev-arrow {
   left: -20px;
 }
 
 .custom-arrow:hover {
   background-color: #f7f7f7;
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
 }
 