
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.BigshotOneFont {
  font-family: 'Bigshot One', sans-serif;
  font-size: 4rem; /* Adjust as needed for larger font */
  line-height: 1.2;
}
.volkhovFont{
font-family: 'volkhov', sans-serif;
}
:root {
  --primary-color: #4B86B4;
  --secondary-color: #E6EFF6;
  --bs-border-color: #4B86B4 !important;
}
.bg-secondary-color{
  background-color: var(--secondary-color);
}
.bg-lightMe{
  background-color: #f3f4f5;
}
.bg-primary-color{
  background-color:#eef3f5;
}
.bg-primary-color2{
  background-color:#b4b4b4aa !important;
}
.border-primary-me{
  border-color: var(--primary-color) !important;
}
.text-primary-me{
  color: var(--primary-color) !important;
}
.layer{
  background-color: #e6eef56a;
  width: 100%;
  height: 100%;
  line-height: 2.2;

}
.layer2{
  background-color: #e6eef599;
  width: 100%;
  height: 100%;
}
.layer2 h4 {
  font-size: 1.8rem;
}

.layer2 p {
  font-size: 1.2rem;
}
.layer h4 {
  font-size: 1.8rem;
}

.layer p {
  font-size: 1.2rem;
}
.layer3{
  background-color: #3c5265af;
  width: 100%;
  height: 100%;
}
.layer3 h4 {
  font-size: 1.8rem;
}

.layer3 p {
  font-size: 1.2rem;
}
.auth-box .user-box {
  position: relative;
}
.no-caret::after {
  display: none !important; /* Hides the arrow */
}
.z-999{
  z-index: 999999999999 !important;
}
.bgPrimaryColor{
  background-color: var(--primary-color) !important;
}
.textPrimaryColor{
  color: var(--primary-color) !important;
}
.auth-box .user-box input {
  width: 100%;
  padding: 10px 10px;
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
  border: none;
  border: 1px solid;
  outline: none;
  background: transparent;
  border-radius: 3px;
}
.auth-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 3px;
  margin: 8px;
  margin-bottom: 10px;
  font-size: 16px;
  background-color: #fff;
  pointer-events: none;
  transition: .5s;
  
}
.auth-box .user-box input:focus ~ label,
.auth-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  /* color: #03e9f4; */
  font-size: 12px;
}
.button-style {
  background-color: var(--primary-color);
  color: #fff;
  padding: 8px 20px;
  border-color: #4B86B4;
  border-radius: 3px;
  cursor: pointer;
}

.word-between {
  position: relative;
  margin-bottom:0px ;
  height: 1px;
  background-color:rgb(33 37 41 / 75%);
  margin: 8% 0;
}

.word-between .word::before {
  content: 'Or login with';
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 25%;
  background-color: #fff;
}
.word-between .word2::before {
  content: 'Or Sign up with';
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 25%;
  background-color: #fff;
}
.cursor-pointer{
  cursor: pointer;
}
.border-width{
  border-style: solid !important;
  border-width: 0.5px !important;
}
.fs-7{
  font-size:70px;
  font-weight: 700;
}
.z-index{
  z-index: 99999 !important;
}

.top-85{
  top: 85% !important;
}
.top-90{
  top: 90% !important;
}
.textMain{
  color: var(--primary-color);
}
.w-60{
  width: 48%;
}
.start-20{
  left: 20%;
}

.top-20{
  top: 30px;
}
.left-2{
  left: 2%;
}
.top-70{
  top: 70%;
}
.end-3{
  right: 3% !important;
}
.h-me{
  height: 100%;
}
.vh-md-100{
  height: 100vh !important;
}
.w-95{
  width: 95%;
}
.w-5{
  width: 5%;
}
.w-25{
  width: 25%;
}
.p-top-9{
  padding-top: 9%;
}
/* Hide the sidebar in the DateRangePicker */
.rdrDefinedRangesWrapper {
  display: none;
}
.hotelFixedImage {
  width: 150px; 
  height: 270px; 
  object-fit: cover; 
  border-radius: 26px; 
}
/* Fade-in animation */
.fade-in-animation {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeIn 1.5s ease-out forwards;
}
/* Button size adjustments */
.custom-btn {
  padding: 1rem 2.5rem; 
  font-size: 1.25rem;
  transition: background-color 0.3s ease, transform 0.3s ease; 
}
.custom-btn:hover {
  transform: scale(1.03) !important; 
  text-decoration: none; 
}
.avatar-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.avatar-hover:hover {
  transform: scale(1.2); /* Slightly enlarge the image on hover */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add a shadow effect */
  cursor: pointer; /* Ensure the pointer indicates a clickable item */
}
.hover-effect {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.hover-effect:hover {
  transform: scale(1.05); /* Slightly enlarge the link on hover */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add a shadow effect */
}

.text-me{
  font-size: medium;
}
.fw-bold-me{
  font-weight: 500;
}
.nav-icon{
  padding: 1px 5px;
  padding-bottom: 3px;
  background-color: #f3f4f5;
}
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 576px) { 
  .vh-md-100{
    height: 40vh !important;
  }
 }
@media (max-width: 1148px) { 
  .text-me{
    font-size: 14px !important;
  }
 }
 @media (max-width: 1061px) { 
  .text-me{
    font-size: 12px !important;
  }
 }
 /* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

 /* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

 /* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}
