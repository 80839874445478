.hero {
   background-image: url('../../../assets/facilites//image_about-bg.png');
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   height: 300px;
   margin-top: 110px;
}

.SingleRestaurant{
   padding-top: 110px;
}

.background-back {
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   height: 100vh;
   width: 100%;
}

 /* Tabs Section */
 .custom-toggle-nav .nav-link {
   padding: 10px 20px;
   font-size: 1rem;
   border: none;
   border-radius: 4px;
   background-color: #f8f9fa;
   transition: all 0.3s ease;
 }
 
 .custom-toggle-nav .nav-link.active {
   background-color: #007bff;
   color: white;
 }
 
 .custom-toggle-nav .nav-link:hover {
   background-color: #0056b3;
   color: white;
 }
 
 /* Responsive Images */
 img.w-100 {
   border-radius: 8px;
 }
 
 /* Item Grid */
 @media (max-width: 576px) {
   .col-12 {
     margin-bottom: 20px;
   }
 }
 