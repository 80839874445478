.filters-container {
   padding: 20px;
   max-width: 300px;
   background-color: #fff;
 }
 
 .filter-section h4 {
   font-size: 1.1em;
   margin-bottom: 10px;
 }
 
 .price-values {
   display: flex;
   justify-content: space-between;
   margin-top: 10px;
 }
 
 .rating-buttons {
   display: flex;
   gap: 10px;
 }
 
 .rating-button {
   padding: 5px 10px;
   border: 1px solid #0d6efd;
   background: #fff;
   cursor: pointer;
   border-radius: 4px;
 }
 
 .rating-button.active {
   background: #0d6efd;
   color: #fff;
 }
 
 .freebies-checkboxes label {
   display: block;
   margin-bottom: 10px;
 }
 
 hr {
   margin: 20px 0;
   border: 1px solid #eee;
 }
 