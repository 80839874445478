.bannerBg{
      background-image: url("../../assets/contact/image\ 1\ \(2\).png");
      height: 100vh;
      background-position:100% 100%;
      background-repeat: no-repeat;
      background-size: cover;
   }
   .bannerBgColor{
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,0) 88%, rgba(255,255,255,1) 100%);
      height: 100vh;
}
.wanderlustBg{
      background-image: url("../../assets/contact/Rectangle\ 19353.png");
      height: 50vh;
      background-position:100% 100%;
      background-repeat: no-repeat;
      background-size: cover;
   }
   .overLay{
      background-image: url("../../assets/contact/Rectangle\ 19303.png");
}
