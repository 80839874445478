.bgImage{
  background-image: url('../../assets//footer/Group\ 237662.png') !important;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  /* height: 200% !important; */
}


 
