
.modal-content {
   margin-top: 200px;
   border-radius: 10px;
   background-color: #f8f9fa;
   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
 }
 
 .modal-title {
   font-size: 1.5rem;
   font-weight: bold;
   color: #333;
 }
 
 .modal-body {
   font-size: 1rem;
   color: #555;
 }
 
 .modal-footer .btn-primary {
   background-color: #007bff;
   border: none;
 }
 