
.bgImage1{
   background-image: url('../../assets/swimming pools/Swimming Pool4.JPG') !important;
   background-position: 50% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height: 100vh;
}

@media (max-width: 992px) {
   .bgImage{
      background-size: cover;
      height: 50vh;
      width: 100%;
   }
   .contact{
      padding-left: 50px;
      padding-right: 50px;
   }
}

.bgImage1 {
   background-size: cover;
   background-position: center;
   height: 300px; /* Adjust height for smaller screens */
 }
 
 @media (min-width: 768px) {
   .bgImage1 {
     height: 500px; /* Larger height for tablets and desktops */
   }
 }
 
 .layer {
   max-width: 90%;
   margin: auto;
 }
 
 @media (min-width: 992px) {
   .layer {
     max-width: 70%;
   }
 }
 