@import url("https://fonts.googleapis.com/css?family=Baloo+Paaji");

/* :root {
  --primary-color: #1e90ff;
  --secondary-color: #ffe221;
  --tertiary-color: #ffffff;
} */

/* html,
body {
  height: 100%;
} */

.animationStyle {
  font-family: "Baloo Paaji", cursive;
  /* background: var(--primary-color); */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
}

.container {
  width: 400px;
  height: 220px;
  position: relative;
}
.hFontStyle{
  font-size: 75px;
  text-transform: uppercase;
}
.span{
  width: 100%;
  float: left;
  /* color: var(--tertiary-color); */
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  transform: translateY(-50px);
  opacity: 0;
  animation-name: titleAnimation;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.spanH1{
  animation-delay: 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.spanH1:first-child {
  animation-delay: 0.7s;
}
.spanH1:last-child {
  color: var(--primary-color);
  animation-delay: 0.5s;
}
/* .h2Style{
  top: 0;
  position: absolute;
} */

@keyframes titleAnimation {
  0% {
    transform: translateY(-50px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  20% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  80% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
    clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
  }
}



@keyframes grad-move {
  0% { background-position: 0 50%}
  50% { background-position: 100% 50%}
  100% { background-position: 0 50%}
}

@keyframes rad-move {
  0%{ border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
  10%{ border-radius: 53% 47% 33% 67% / 60% 30% 70% 40%; }
  20%{ border-radius: 71% 29% 38% 62% / 72% 48% 52% 28%; }
  30%{ border-radius: 41% 59% 45% 55% / 33% 56% 44% 67%; }
  40%{ border-radius: 55% 45% 67% 33% / 77% 48% 52% 23%; }
  50%{ border-radius: 44% 56% 22% 78% / 31% 74% 26% 69%; }
  60%{ border-radius: 46% 54% 48% 52% / 61% 59% 41% 39%; }
  70%{ border-radius: 44% 56% 60% 40% / 63% 36% 64% 37%; }
  80%{ border-radius: 20% 80% 45% 55% / 31% 79% 21% 69%; }
  90%{ border-radius: 66% 34% 57% 43% / 49% 51% 49% 51%; }
  100%{ border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
}

/* :root {
  background: linear-gradient(
  	-225deg, 
  	#ffe29f 0%, 
  	#ffa99f 50%, 
  	#ff719a 100%);
} */

.h1Animation {
  color:#fff !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin: 0;
	font-family: 'Quicksand', sans-serif;
	font-size: 2.25rem;
	line-height: 2.35rem;
	letter-spacing: .05rem;
  color: #000000c6;
}

.box {
  position:absolute;
  height: 180px;
  width:180px;
  left:50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.shape {
  position:absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to right, 
		#ffffff, 
		#e6eff6a1);
	background-size: 1000%;
	background-repeat: no-repeat;
	border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  opacity: .6;
	box-shadow: 7px 10px 38px 4px rgba(0,0,0,0.2);
	animation: 
		grad-move 60s linear infinite, 
    rad-move 30s linear infinite;
}
.shape2 {
  position:absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to right, 
		#4b87b4, 
		#b9c0c6a1);
	background-size: 1000%;
	background-repeat: no-repeat;
	border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  opacity: .6;
	box-shadow: 7px 10px 38px 4px rgba(0,0,0,0.2);
	animation: 
		grad-move 60s linear infinite, 
    rad-move 30s linear infinite;
}
.shape:nth-child(1) {
  transform: rotate(60deg);
  animation-delay: .5s;
}

.shape:nth-child(2) {
  transform: rotate(120deg);
  animation-delay: 1s;
}
.shape2:nth-child(1) {
  transform: rotate(60deg);
  animation-delay: .5s;
}

.shape2:nth-child(2) {
  transform: rotate(120deg);
  animation-delay: 1s;
}
.irrelativeMovement{
  position: absolute;
  top: 20%;
  right: 10%;
}
.irrelativeMovementText{
  position: absolute;
  bottom: 25%;
  left:5%;
}
.fontAnimation{
  font-size: 20px;
}
.fontAnimation2{
  font-size: 80px;
  font-weight:lighter;
}
.boxTitle {
  position:absolute;
  height: 280px;
  width:280px;
  left:50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
/* Mobile styles */
@media (max-width: 767px) {
  .box {
    height: 100px; /* Reduce the height */
    width: 100px;  /* Reduce the width */
  }
  
  .irrelativeMovement {
    top: 30%;  /* Adjust the positioning */
    right: 20%; /* Adjust the positioning */
  }
  
  .h1Animation {
    font-size: 1.25rem; /* Adjust font size */
    line-height: 1.5rem;
  }
  
  .fontAnimation {
    font-size: 16px; /* Adjust smaller text size */
  }
  
  .fontAnimation2 {
    font-size: 40px; /* Adjust larger text size */
  }
  
  .boxTitle {
    height: 160px; /* Reduce the height */
    width: 160px;  /* Reduce the width */
  }
}
/* Mobile styles */
@media (max-width: 767px) {
  .irrelativeMovementText {
    bottom: 25%;  /* Adjust the positioning */
    left: 27%;    /* Adjust the positioning */
  }

  .boxTitle {
    height: 160px; /* Reduce the height */
    width: 160px;  /* Reduce the width */
  }

  .fontAnimation {
    font-size: 14px; /* Adjust smaller text size */
  }

  .fontAnimation2 {
    font-size: 30px; /* Adjust larger text size */
  }

  .h1Animation {
    font-size: 1.25rem; /* Adjust font size */
    line-height: 1.5rem;
  }
}
