.bookRoom{
   height: 75vh;
   background-image: url('../assets/Main/room.JPG');
}

.bookRoom {
   padding: 20px;
 }
 
 .layer2 {
   background-color: #fff;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }
 
 @media (max-width: 768px) {
   .bookRoom {
     padding: 10px;
   }
 
   .layer2 {
     padding: 15px;
   }
 
   .form-label {
     font-size: 14px;
   }
 
   .btn {
     font-size: 14px;
     padding: 10px;
   }
 
   .row > div {
     flex: 0 0 100%;
     max-width: 100%;
   }
 
   .col-12 {
     margin-bottom: 15px;
   }
 }
 
 @media (max-width: 576px) {
   .bookRoom {
     padding: 5px;
   }
 
   .form-select {
     font-size: 14px;
     padding: 5px;
   }
 
   .btn {
     font-size: 12px;
     padding: 8px;
   }
 }
 