.mainSlider {
  height: 100vh !important;
}

.item2 {
  height: 450px !important;
  max-width: 550px !important;
  box-sizing: border-box;
}

.sliderItem {
  padding-right: 50px !important;
}

.sliderItemPadding {
  padding-right: 50px !important;
}

.w-50-me {
  width: 50%;
}

.bottom-me {
  bottom: 50%;
}

/* For Tablets */
@media (max-width: 900px) {
  .item2 {
    max-width: 450px !important;
    height: 350px !important;
    margin: auto !important;
  }
  .desc h1 {
    font-size: 1.5rem; /* Reduce font size for tablets */
  }
  .desc p {
    font-size: 1rem; /* Reduce font size for tablets */
  }
}

/* For Mobile Phones */
@media (max-width: 650px) {
  .w-50-me {
    width: max-content;
  }
  .bottom-me {
    bottom: 75%;
    width: 100%;
  }
  .hidden {
    display: none;
  }
  .sliderItem {
    padding-right: 0px !important;
  }
  .sliderItemPadding {
    padding-right: 0px !important;
  }
  .item2 {
    max-width: 450px !important;
    height: 250px !important;
  }
  .desc h1 {
    font-size: 1.2rem; /* Reduce font size for mobile */
  }
  .desc p {
    font-size: 0.8rem; /* Reduce font size for mobile */
  }
}

/* For screens less than 1100px */
@media (max-width: 1100px) {
  .mainSlider .slider .slick-slide {
    width: 100% !important; /* Full width for background slider */
  }

  .desc {
    position: absolute;
    bottom: 5%;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
  }
  .desc h1 {
    font-size: 2rem; /* Adjust font size for medium screens */
  }
  .desc p {
    font-size: 1.2rem; /* Adjust font size for medium screens */
  }
}

/* Content Styling */
.content {
  width: min(30vw, 400px);
  position: absolute;
  top: 50%;
  left: 3rem;
  transform: translateY(-50%);
  font: 400 0.85rem helvetica, sans-serif;
  color: white;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: none;
}

.item:nth-of-type(2) .content {
  display: block;
  animation: show 0.75s ease-in-out 0.3s forwards;
}

@keyframes show {
  0% {
    filter: blur(5px);
    transform: translateY(calc(-50% + 75px));
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.nav {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  user-select: none;
}

.nav .btn {
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.7);
  border: 2px solid rgba(0, 0, 0, 0.6);
  margin: 0 0.25rem;
  padding: 0.75rem;
  border-radius: 50%;
  cursor: pointer;
}

.nav .btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
