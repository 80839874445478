.profile-image-wrapper {
   width: fit-content;
   margin: auto;
 }
 
 .profile-image {
   width: 120px;
   height: 120px;
 }

 .toggle-link {
   display: block;
   padding: 1rem;
   font-size: 1.2rem;
   color: #4B86B4;
   cursor: pointer;
   transition: color 0.3s ease;
 }
 
 .toggle-link:hover {
   color: #0056b3;
 }
 
 .toggle-link.active {
   color: #0056b3;
   font-weight: bold;
   border-bottom: 3px solid #0056b3;
 }
 
 .card {
   border: 1px solid #ddd;
   border-radius: 8px;
   padding: 1rem;
   background-color: #f8f9fa;
 }
 
 .card-body {
   padding: 1.5rem;
 }
 .collapse {
   transition: max-height 0.3s ease, opacity 0.3s ease;
 }
 