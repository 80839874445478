.bgImage{
   background-image: url('../../../assets/hotels//image\ 1\ \(1\).png') !important;
   background-position: 100%;
}

.bgHotelRoom{
   background-image: url("../../../assets/hotels/Group\ 238.png");
   background-position:100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
}
.category{
   position: relative;
}
.categoryName{
   position: absolute;
   top: 75%;
   left:7%;
}
.fixedImage {
   width: 150px; /* Fixed width */
   height: 270px; /* Fixed height */
   object-fit: cover; /* Ensures the image scales properly */
   border-radius: 26px; /* Optional: Add rounded corners */
 }

 /* Apply hover effects */
.item:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}



/* Ensure images have consistent size */
.fixedImage {
  height: 200px; /* Set a fixed height */
  object-fit: cover;
  border-radius: 8px;
}
.fixedImage:hover {
   color: #f9a826; /* Add a hover color */
   transition: color 0.3s ease-in-out;
 }
/* Adjust category name text */
.categoryName {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6); /* Add a background to improve readability */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
}
