.mainSlider{
  height: 100vh !important;
}
 .item2 {
  height: 450px !important;
  max-width: 550px !important;
  box-sizing: border-box; /* Ensure padding and borders are included in the height calculation */
}
.sliderItem{
  padding-right: 50px !important;
}
.sliderItemPadding{
  padding-right: 50px !important;
}
.w-50-me{
  width: 50%;
}
.bottom-me{
  bottom: 50%;
}
 /* For Tablets */
@media (max-width: 900px) {
  .item2 {
    max-width: 450px !important;
    height: 350px !important;
    margin: auto !important;
  }
}

/* For Mobile Phones */
@media (max-width: 650px) {
  .w-50-me{
    width: max-content;
  }
  .bottom-me{
    bottom: 75%;
    width: 100%;
  }
  .hidden{
    display: none;
  }
  .sliderItem{
    padding-right: 0px !important;
  }
  .sliderItemPadding{
    padding-right: 0px !important;
  }
  .item2 {
    max-width: 450px !important;
    height: 250px !important;
  }
} 
 .content {
   width: min(30vw,400px);
   position: absolute;
   top: 50%;
   left: 3rem;
   transform: translateY(-50%);
   font: 400 0.85rem helvetica,sans-serif;
   color: white;
   text-shadow: 0 3px 8px rgba(0,0,0,0.5);
   opacity: 0;
   display: none;
 }
 
 .item:nth-of-type(2) .content {
   display: block;
   animation: show 0.75s ease-in-out 0.3s forwards;
 }
 
 @keyframes show {
   0% {
     filter: blur(5px);
     transform: translateY(calc(-50% + 75px));
   }
   100% {
     opacity: 1;
     filter: blur(0);
   }
 }
 
 .nav {
   position: absolute;
   bottom: 2rem;
   left: 50%;
   transform: translateX(-50%);
   z-index: 5;
   user-select: none;
 }
 
 .nav .btn {
   background-color: rgba(255,255,255,0.5);
   color: rgba(0,0,0,0.7);
   border: 2px solid rgba(0,0,0,0.6);
   margin: 0 0.25rem;
   padding: 0.75rem;
   border-radius: 50%;
   cursor: pointer;
 
   &:hover {
     background-color: rgba(255,255,255,0.3);
   }
 }
 
 @media (width > 650px) and (width < 900px) {
   .content {
     .title { font-size: 1rem; }
     .description { font-size: 0.7rem; }
     button { font-size: 0.7rem; }
   }
   .item {
     width: 160px;
     height: 270px;
 
     &:nth-child(3) { left: 50%; }
     &:nth-child(4) { left: calc(50% + 170px); }
     &:nth-child(5) { left: calc(50% + 340px); }
     &:nth-child(6) { left: calc(50% + 510px); opacity: 0; }
   }
 }
 
 @media (width < 650px) {
   .content {
     .title { font-size: 0.9rem; }
     .description { font-size: 0.65rem; }
     button { font-size: 0.7rem; }
   }
   .item {
     width: 130px;
     height: 220px;
 
     &:nth-child(3) { left: 50%; }
     &:nth-child(4) { left: calc(50% + 140px); }
     &:nth-child(5) { left: calc(50% + 280px); }
     &:nth-child(6) { left: calc(50% + 420px); opacity: 0; }
   }
 }
 .slider-container {
  width: 100%;
  height: 100%;
}

.slide {
  position: relative;
}

.slide-image-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
}

.slide-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.slide-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  width:80%;
}

.slide-text h3 {
  font-size: 36px;
  margin-bottom: 10px;
}

.slide-text p {
  font-size: 18px;
}
/* home-slider.css */

/* For screens smaller than or equal to 1100px */
@media (max-width: 1100px) {
  .desktop-slider {
    display: none;
  }

  .mobile-slider {
    display: block;
  }
}

/* For larger screens */
@media (min-width: 1101px) {
  .desktop-slider {
    display: block;
  }

  .mobile-slider {
    display: none;
  }
}
