/* Container for the navigation links */
.custom-toggle-nav {
   display: flex;
   gap: 20px;
   padding-bottom: 8px;
   border-bottom: 1px solid #eaeaea;
 }
 
 /* Style for individual navigation links */
 .custom-toggle-nav .nav-link {
   color: #7b7b7b;
   font-weight: 500;
   position: relative;
   background: none;
   border: none;
   padding: 0;
   font-size: 16px;
   cursor: pointer;
 }
 
 /* Active link style */
 .custom-toggle-nav .nav-link.active {
   color: #333;
   font-weight: bold;
 }
 
 /* Underline effect for the active link */
 .custom-toggle-nav .nav-link.active::after {
   content: '';
   position: absolute;
   left: 0;
   bottom: -8px;
   width: 100%;
   height: 2px;
   background-color: #d9534f; /* Red color for underline */
 }
 