.dropdown-item:focus {
   background-color:  #4B86B4;
   color: white;
 }
 
 .dropdown-item {
   padding: 10px;
 }
 
 .dropdown-toggle {
   border: none;
   box-shadow: none;
 }
 
 .dropdown-menu {
   border-radius: 10px;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
 }
 