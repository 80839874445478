.bgImage{
   background-image: url('../../../assets/hotels//image\ 1\ \(1\).png') !important;
   background-position: 100%;
}

.bgHotelRoom{
   background-image: url("../../../assets/hotels/Group\ 238.png");
   background-position:100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
}
.category{
   position: relative;
}
.categoryName{
   position: absolute;
   top: 75%;
   left:7%;
}