.bgImage{
   background-image: url('../../../assets/hotels//Group\ 89.png') !important;
   background-position: center; /* Aligns the image to the center and top */
   background-repeat: no-repeat;
   background-size: cover; 
   height: 100vh;
}

.bgWhyUs{
   background-image: url('../../../assets/hotels/Group\ 24942.png');
background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height: 100vh;
}

.bgWhyUsContent{
   background-image: url('../../../assets/hotels/Group\ 2494.png') !important;
background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
}

.bgHotelRoom{
   background-image: url("../../../assets/hotels/Group\ 238.png");
   background-position:100% ;
   background-repeat: no-repeat;

}
.category{
   position: relative;
}
.categoryName{
   position: absolute;
   top: 75%;
   left:7%;
}
.bgImage1{
   background-image: url('../../../assets/hotels/rooms.JPG') !important;
   background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height:50vh;
}
.bgImage2{
   background-image: url('../../../assets/hotels/Rectangle\ 9.png') !important;
   background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height:50vh;
}
.bgImage3{
   background-image: url('../../../assets/hotels/Rectangle\ 10.png') !important;
   background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height:50vh;
}
.bgImage4{
   background-image: url('../../../assets/hotels/Rectangle\ 11.png') !important;
   background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height:50vh;
}
.bgImage5{
   background-image: url('../../../assets/hotels/Rectangle\ 8.png') !important;
   background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height:50vh;
}
.bgImage6{
   background-image: url('../../../assets/hotels/Beach\ sun\ rise\ overview\ ok.JPG') !important;
   background-position: 100% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height:50vh;
}

.discountBg{
   background-image: url('../../../assets/Main/Frame\ 2411.png') !important;
   background-position: 100%;
   background-repeat: no-repeat;
   background-position: left;
}